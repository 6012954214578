import React from "react";
import Img from "gatsby-image"
import { withTranslation } from "react-i18next";
import './wellness.scss'
import SocialShare from "../SocialShare"
import QuestionBubble from "../QuestionBubble"
import Heading from "../Heading"
import Meta from "../Meta";

class Wellness extends React.Component {

    render() {

        const { data, content, t } = this.props

        return(
            <div id="wellness" class="blog-area blog-no-sidebar pt-100 pb-100">
            <Meta
                keywords={data.keywords}
                description={data.description}
            />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                    <Heading title={data.pageTitle} subtitle={data.pageSubtitle} className="mb-50" />

                                    <div dangerouslySetInnerHTML={{__html: content}} />

                                    <a className="info-btn text-center" href={t('bathing-link')} target="_blank">
                                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                        {data.bathingLinkTitle}
                                    </a>

                                    <div class="row">
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image1.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image3.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image4.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image6.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image5.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image2.childImageSharp.fluid} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-40 full-width">
                                <div class="col-md-6">
                                    <blockquote class="mb-50">
                                        {data.blockquote}
                                    </blockquote>
                                </div>
                                <div class="col-md-6">
                                    <QuestionBubble 
                                        title={data.questionTitle} 
                                        description={data.questionDescription} 
                                        phone={data.questionPhone}  
                                        email={data.questionEmail} />
                                </div>
                            </div>
                            <div class="common-tag-and-next-prev">
                                <SocialShare title={data.title} hashtags="wellness"/>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
        )
    }
}
export default withTranslation()(Wellness)