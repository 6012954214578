import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Wellness from "../components/Wellness"

const WellnessPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title} keywords={fields.keywords} description={fields.description}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} bgOffset="-80" />
          <Wellness data={fields} content={data.page.html} />
      </Layout>
    );
};
export default withI18next()(WellnessPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      html
      frontmatter {
        slug
        title
        pageTitle
        pageSubtitle
        bathingLinkTitle
        wellnessClosingText
        questionTitle
        questionDescription
        questionPhone
        questionEmail
        blockquote
        contentDescription
        keywords
        description
        image1 {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "bg/wellness_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  }`